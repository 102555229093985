import "../About.css";

function About(){
    return(
        <div className="text-container">
            <h1>
                Space of Hearts
            </h1>
            <p>
            Established on November 29, 2023, and headquartered at 01
          Meadlake Place, Thorpe Lea Road, Egham, Surrey, TW20 8HE, we
          specialize in the letting and operation of our own real estate
          properties.
            </p>

        </div>
    )
}

export default About;