import SocialMedia from "./SocialMedia";
function Footer() {
  return (
    <div className="Footer">
      <SocialMedia />
    </div>
  );
}

export default Footer;
