import Main from "./components/Main"
import './App.css';

function App() {
  return (
    <div className="App">

    <Main/>

    </div>
  );
}

export default App;
