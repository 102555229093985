import TER from "../Terreced.png";

function Content() {
  return (
    <div className="image-container">
      <img src={TER} alt="House" className="ImgContent" />
      <div className="text-overlay">
        We find spaces and create homes
      </div>
    </div>
  );
}

export default Content;
